import SelectUserButton from '@frontend/jetlend-web-ui/src/components/SelectUserButton/SelectUserButton';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    isMobile,
    isTablet,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import DropdownActions from '@frontend/jetlend-web-ui/src/components/DropdownActions/DropdownActions';
import { UserButton } from '@frontend/jetlend-web-ui/src/components/UserButton/UserButton';
import AccountTypeBadge from '@frontend/jetlend-web-ui/src/components/AccountTypeBadge/AccountTypeBadge';
import AddUserButton from '@frontend/jetlend-web-ui/src/components/AddUserButton/AddUserButton';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import arrowLeft from '@frontend/jetlend-assets/icons/icon--arrow-left-light-gray.svg';
import { getAssetSrc } from '@frontend/jetlend-web-ui/src/utils/getAssetSrc';
import useModalHandler from '@frontend/jetlend-core/src/hooks/useModalHandler';
import useFormSubmitter from '@frontend/jetlend-core/src/hooks/useFormSubmitter';
import greenPlus from '@frontend/jetlend-assets/icons/icon-plus--green-rounded.svg';
import {
    ClientType,
    CLIENT_TYPE_CABINET_TARIFF_URLS,
    CLIENT_TYPE_CABINET_URLS,
    getClientTitle,
    CLIENT_TYPE_CREATE_URLS,
    CLIENT_TYPE_NAMES,
} from '@app/models/common/common';
import {
    fetchUser,
    getUsers,
    userModalHandler,
} from '@app/ducks/common/account';

import styles from './connectedSelectUserButton.module.scss';
import { IAccountInfoApiModel } from '@app/models/common/account';

export interface IProps {
    /**
     * Объект с информацией об аккаунте
     */
    account: IAccountInfoApiModel;
}

export default function ConnectedSelectUserButton ({ account }: IProps) {
    const { width: screenWidth } = useWindowSize();
    const mobile = isMobile(screenWidth);
    const dispatch = useDispatch();
    const users = useSelector(getUsers);
    const [ isOpen, open, close ] = useModalHandler(userModalHandler);
    const [ submit ] = useFormSubmitter(userModalHandler);


    const [isAddUserButtonOpen, setIsAddUserButtonOpen] = useState(false);

    const clientType = CLIENT_TYPE_NAMES[account?.client_type];
    const linkTitle = `Личный кабинет ${getClientTitle(clientType)}`;

    const didUserSwitched = useCallback((type: ClientType, id: number) => {
        submit({
            client_id: id,
            client_type: type,
        });

        close();
    }, [ submit, close ]);

    const didModalOpened = useCallback(() => open(), [ open ]);
    const didModalClosed = useCallback(() => close(), [ close ]);

    const accountName = useMemo(() => {
        if (!account?.name) {
            return CLIENT_TYPE_NAMES[account?.client_type];
        }

        const { name } = account;
        if (isTablet(screenWidth) || mobile) {
            const parts = name.split(' ');
            if (parts.length === 3) {
                // Пример: Иванов И.И.
                return `${parts[0]} ${parts[1].charAt(0)}. ${parts[2].charAt(0)}.`;
            }
        }

        return name;
    }, [account, mobile, screenWidth]);

    useEffect(() => {
        dispatch(fetchUser());
    }, [ dispatch ]);

    const didCreateButtonClicked = useCallback(() => {
        setIsAddUserButtonOpen(prevState => !prevState);
    }, []);

    const didMenuClosed = useCallback(() => setIsAddUserButtonOpen(false), []);

    return (

        <DropdownActions
            // Hover используется только на мобильных устройствах в связи со спецификой работы tippy с touch событиями
            // А именно, функция onClickOutside, отрабатывает не корректно, и вызывается даже при таче на попап.
            hover={mobile}
            button={(
                <SelectUserButton
                    name={accountName}
                    loading={!accountName}
                    onClick={didModalOpened}
                    onClose={didModalClosed}
                    isOpen={isOpen}
                >
                    <AccountTypeBadge href={CLIENT_TYPE_CABINET_TARIFF_URLS[account?.client_type]} type={account?.account_type} />
                </SelectUserButton>
            )}
            // Так как используется hover, для мобильных устройств.
            // Нам не нужно управлять состоянием открытия вручную.
            // Вместо нас этим будет заниматься tippy
            isOpen={!mobile && isOpen}
            onClose={didModalClosed}
        >
            <AddUserButton
                isOpen={isAddUserButtonOpen}
                onClose={didMenuClosed}
                href={CLIENT_TYPE_CREATE_URLS[account?.client_type]}
            >
                <div className={styles['container']}>
                    <div className={styles['content']}>
                        {account?.client_type && (
                            <DropdownActions.Link className={styles['link']} href={CLIENT_TYPE_CABINET_URLS[account?.client_type]}>
                                <div>{linkTitle}</div>
                                <img className={styles['arrow']} src={getAssetSrc(arrowLeft)} alt="" />
                            </DropdownActions.Link>
                        )}
                        {users?.investors?.length > 0 && (
                            <>
                                <DropdownActions.Section title="Инвесторы" />
                                {users.investors.map(item => (
                                    <UserButton
                                        key={item.id}
                                        type="investor"
                                        id={item.id}
                                        name={item.name}
                                        onSwitchUser={didUserSwitched}
                                    />
                                ))}
                            </>
                        )}
                        {users?.borrowers?.length > 0 && (
                            <>
                                <DropdownActions.Section title="Заемщики" />
                                {users.borrowers.map(item => (
                                    <UserButton
                                        key={item.id}
                                        type="borrower"
                                        id={item.id}
                                        name={item.name}
                                        onSwitchUser={didUserSwitched}
                                    />
                                ))}
                            </>
                        )}
                        {users?.partners?.length > 0 && (
                            <>
                                <DropdownActions.Section title="Партнеры" />
                                {users.partners.map(item => (
                                    <UserButton
                                        key={item.id}
                                        type="partner"
                                        id={item.id}
                                        name={item.name}
                                        onSwitchUser={didUserSwitched}
                                    />
                                ))}
                            </>
                        )}
                        <DropdownActions.Button onClick={didCreateButtonClicked}>
                            <div className={styles['text__container']}>
                                <img src={getAssetSrc(greenPlus)} alt="Добавить аккаунт" />
                                <div className={styles['text']}>Добавить аккаунт</div>
                            </div>
                        </DropdownActions.Button>
                    </div>
                </div>
            </AddUserButton>
        </DropdownActions>
    );
};