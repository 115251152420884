import React, {
    PropsWithChildren,
    useCallback,
    useMemo,
} from 'react';
import DropdownActions from '@frontend/jetlend-web-ui/src/components/DropdownActions/DropdownActions';

import styles from './addUserButton.module.scss';

export interface IProps extends PropsWithChildren {
    /**
     * Открыт ли дропдаун
     */
    isOpen: boolean;
    /**
     * Закрытие дропдауна
     */
    onClose: () => void;
    /**
     * Создание юзера
     * @param type - тип юзера
     */
    onCreate?: (type: string) => void;
    /**
     * Ссылка на создание юзера
     */
    href?: string;
}

export default function AddUserButton ({
    isOpen,
    onClose,
    onCreate,
    href,
    children,
}: IProps) {

    const buttonsData = useMemo(() => [
        {
            title: 'Инвестор',
            onClick: () => onCreate && onCreate('investor'),
            href,
        },
        {
            title: 'Заёмщик',
            onClick: () => onCreate && onCreate('borrower'),
            href,
        },
        {
            title: 'Партнер',
            onClick: () => onCreate && onCreate('partner'),
            href,
        },
    ], [onCreate, href]);

    const didClose = useCallback(() => onClose(), [onClose]);

    return (
        <DropdownActions
            button={children}
            isOpen={isOpen}
            onClose={didClose}
            placement="bottom"
        >
            {buttonsData.map(item =>
                onCreate
                    ? (
                        <DropdownActions.Button
                            onClick={item.onClick}
                            key={item.title}
                            className={styles['user-type__button']}
                        >
                            {item.title}
                        </DropdownActions.Button>
                    )
                    : (
                        <DropdownActions.Link
                            href={item.href}
                            key={item.title}
                            className={styles['user-type__button']}
                        >
                            {item.title}
                        </DropdownActions.Link>
                    )
            )}
        </DropdownActions>
    );
}
